import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from './components/Main.js';
import Waitlist from './components/Waitlist.js';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/waitlist" element={<Waitlist />} />
      </Routes>
    </Router>
  );
}

export default App;
