import React, { useState, useEffect } from 'react';
import '../styles/Auth.css';

const Waitlist = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    city: '',
    state: '',
    email: ''
  });
  const [locationInput, setLocationInput] = useState('');
  const [submitStatus, setSubmitStatus] = useState('');

  useEffect(() => {
    initAutocomplete();
  }, []);

  const initAutocomplete = () => {
    if (window.google && window.google.maps && window.google.maps.places) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        document.getElementById('location-input'),
        {
          types: ['locality'],
          componentRestrictions: { country: 'us' },
          fields: ['address_components', 'geometry', 'name'],
        }
      );

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        handlePlaceSelect(place);
      });
    }
  };

  const handlePlaceSelect = (place) => {
    if (place && place.address_components) {
      let city = '';
      let state = '';

      for (const component of place.address_components) {
        if (component.types.includes('locality')) {
          city = component.long_name;
        }
        if (component.types.includes('administrative_area_level_1')) {
          state = component.short_name;
        }
      }

      setLocationInput(`${city}, ${state}`);
      setFormData(prevState => ({
        ...prevState,
        city: city,
        state: state
      }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'city') {
      setLocationInput(value);
      setFormData(prevState => ({
        ...prevState,
        city: value,
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:8000';
      console.log('Submitting to:', `${apiUrl}/api/waitlist/join`);
      
      const response = await fetch(`${apiUrl}/api/waitlist/join`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });
      
      if (response.ok) {
        setSubmitStatus('success');
        setTimeout(() => {
          setSubmitStatus('');
          setFormData({ firstName: '', city: '', state: '', email: '' });
          setLocationInput('');
          // Optionally redirect to home page after success
          window.location.href = '/';
        }, 2000);
      } else {
        setSubmitStatus('error');
      }
    } catch (error) {
      setSubmitStatus('error');
    }
  };

  return (
    <div className="main-container">
      <img src="/icons/logo_prime.png" alt="Wenzi Logo" className="main-logo" />
      <h1>Join the Waitlist</h1>
      <p className="main-description">
        Join our waitlist to be one of the first to experience Wenzi!
      </p>
      <div className="auth-form-container">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            placeholder="First Name"
            required
          />
          <input
            id="location-input"
            type="text"
            name="city"
            value={locationInput}
            onChange={handleInputChange}
            placeholder="City"
            required
          />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="Email"
            required
          />
          <button type="submit" className="main-btn waitlist-btn">Submit</button>
        </form>
        {submitStatus === 'success' && (
          <p className="success-message">Thanks for joining the waitlist!</p>
        )}
        {submitStatus === 'error' && (
          <p className="error-message">Something went wrong. Please try again.</p>
        )}
      </div>
      <footer className="auth-footer">
        2024 © Wenzi, Inc.
      </footer>
    </div>
  );
};

export default Waitlist;
