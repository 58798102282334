import React, { useState, useEffect } from 'react';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';


const Main = () => {
  return (
    <div className="main-container">
      <img src="/icons/logo_prime.png" alt="Wenzi Logo" className="main-logo" />
      <h1>Welcome</h1>
      <p className="main-description">
	Wenzi lets you create your own AI friends to text and call - ones that get to know and support you 
	and later connect you with real like-minded people who share your interests!
      </p>
      <div className="main-buttons">
        <a href="/waitlist" className="main-btn waitlist-btn">
          Join Waitlist
        </a>
      </div>

      <footer className="auth-footer">
        2024 © Wenzi, Inc.
      </footer>
    </div>
  );
};

export default Main;
